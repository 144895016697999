import { template as template_4140e6e5c9704307a22cf754e5b4c017 } from "@ember/template-compiler";
const SidebarSectionMessage = template_4140e6e5c9704307a22cf754e5b4c017(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
