import { template as template_e0ce30ee26324edc9332af966c8e9980 } from "@ember/template-compiler";
const WelcomeHeader = template_e0ce30ee26324edc9332af966c8e9980(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
