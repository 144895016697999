import { template as template_a957b3aeac9641c08f89a7c9c1d92166 } from "@ember/template-compiler";
const FKText = template_a957b3aeac9641c08f89a7c9c1d92166(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
